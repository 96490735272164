body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  position: relative;
  min-height: 100vh;
  background-color: rgb(244, 244, 244) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content {
  z-index: 2;
  width: 92%;
  top: 9%;
  left: calc(65px + 1.2vw);
  position: absolute;
  margin-top: 20px;
  background-color: rgb(244, 244, 244);
}

.hover:hover {
  transition: all 100ms ease;
  filter: opacity(0.9);
  cursor: pointer;
}

@media (max-width: 600px) {
  .content {
    width: 100%;
    left: 0;
  }
}
